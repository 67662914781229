import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

// App
import { EmbedOverlayComponentComponent } from './embed-component/embed-overlay-component/embed-overlay-component.component';
import { EmbedComponent } from './embed-component/embed.component';
import { EmbedOverlayServiceService } from './embed-component/embed-overlay-service.service';
import { LiveThemedDirectiveModule } from 'uikit';
import {
  EventDropCardModule,
  LandingPageViewModule,
  EventDropShareButtonModule,
  EventMainButtonModule,
  DropMainButtonModule
} from 'shared';

@NgModule({
  declarations: [EmbedComponent, EmbedOverlayComponentComponent],
  imports: [
    CommonModule,
    PortalModule,
    EventDropCardModule,
    EventDropShareButtonModule,
    EventMainButtonModule,
    DropMainButtonModule,
    LandingPageViewModule,
    LiveThemedDirectiveModule
  ],
  providers: [EmbedOverlayServiceService],
  exports: [EmbedComponent]
})
export class EmbedModule {
  static forChild(inputs: any): EmbedModule {
    return {
      ngModule: EmbedModule,
      providers: []
    };
  }
}

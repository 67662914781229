import { Injectable, Injector } from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';

import { EmbedOverlayComponentComponent } from './embed-overlay-component';
import { createDomPortalHostFromElement } from './helpers';
import { DeviceService } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class EmbedOverlayServiceService {
  constructor(
    readonly injector: Injector,
    private _device: DeviceService
  ) {}

  addDynamicComponent(element: Element) {
    this._addDynamicComponentUsingPortalFromElement(element);
  }

  private _addDynamicComponentUsingPortalFromElement(element: Element) {
    const itemId = element.getAttribute('data-id');
    const itemLabel = element.getAttribute('data-label');
    const itemType = element.getAttribute('data-type');
    const itemVariant = element.getAttribute('data-variant');
    const slug = element.getAttribute('data-slug') ?? '';

    this._device.forceSlug(slug);
    const portalHost: DomPortalOutlet = createDomPortalHostFromElement(
      element,
      this.injector
    );
    const overlayPortal = new ComponentPortal(EmbedOverlayComponentComponent);
    this._removeChildrenFromElement(element);

    const embedOverlayComponentRef = portalHost.attach(overlayPortal);
    embedOverlayComponentRef.instance.itemId = itemId;
    embedOverlayComponentRef.instance.itemType = itemType;
    embedOverlayComponentRef.instance.itemVariant = itemVariant;
    embedOverlayComponentRef.instance.itemLabel = itemLabel;
  }

  private _removeChildrenFromElement(element: Element): void {
    element.childNodes.forEach((node) => node.remove());
    element.childNodes.forEach((node) => node.remove());
  }
}

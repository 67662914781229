import { Component, OnDestroy, AfterViewInit } from '@angular/core';

import { EmbedOverlayServiceService } from './embed-overlay-service.service';
import { NORBY_EMBED_NAMESPACE } from './constants';
import { EMBED_CLASS } from 'models';

@Component({
  selector: 'app-embed',
  template: ``,
  styleUrls: ['./embed.component.less']
})
export class EmbedComponent implements OnDestroy, AfterViewInit {
  constructor(private _embedService: EmbedOverlayServiceService) {
    this._setupListeners();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._renderEmbeds();
    });
  }

  private _setupListeners() {
    window.addEventListener(NORBY_EMBED_NAMESPACE, this._renderCallback);
  }

  private _destroyListeners() {
    window.removeEventListener(NORBY_EMBED_NAMESPACE, this._renderCallback);
  }

  private _renderCallback = () => {
    this._renderEmbeds();
  };

  private _renderEmbeds() {
    const results = document.querySelectorAll(`.${EMBED_CLASS}`);
    results.forEach((res) => this._embedService.addDynamicComponent(res));
  }

  ngOnDestroy(): void {
    this._destroyListeners();
  }
}

// <div
//   class="norby-embed"
//   data-type="event"
//   data-id="efcb3204-b829-49e9-9841-4266352bbaba"
//   data-variant="card"
//   data-slug="homefromcollege"
// >
//   <a
//     href="https://homefromcollege.exp.norby.live/event/efcb3204-b829-49e9-9841-4266352bbaba"
//     target="_blank"
//     rel="noreferrer"
//   >
//     <strong>Testttt Herman For iiiiiCustom styling 👨🏿‍💻</strong><br />
//     August 10, 2022 at 8:50 pm EDT
//   </a>
// </div>

// <div
//   class="norby-embed"
//   data-type="drop"
//   data-id="dfc6ced6-6ef9-449c-85ff-c8f90766d443"
//   data-variant="card"
//   data-slug="homefromcollege"
// >
//   <a
//     href="https://homefromcollege.exp.norby.live/signup/dfc6ced6-6ef9-449c-85ff-c8f90766d443"
//     target="_blank"
//     rel="noreferrer"
//   >
//     <strong>Testttt Herman For iiiiiCustom styling 👨🏿‍💻</strong><br />
//     August 10, 2022 at 8:50 pm EDT
//   </a>
// </div>

<div class="norby-embed" live-themed [theme]="slug?.theme">
  <div live-themed [theme]="contentTheme">
    <!-- EVENT CARD -->
    <lib-event-drop-card
      *ngIf="event && itemVariant == eventCardVariant"
      [content]="event"
      (cardClick)="handleEventDropClick($event)"
    >
    </lib-event-drop-card>

    <!-- EVENT MAIN -->
    <lib-event-main-button
      *ngIf="event && itemVariant == eventMainVariant"
      [event]="event"
    ></lib-event-main-button>

    <!-- EVENT SHARE -->
    <lib-event-drop-share-button
      *ngIf="event && itemVariant == eventShareVariant"
      [content]="event"
    >
    </lib-event-drop-share-button>

    <!-- DROP CARD -->
    <lib-event-drop-card
      *ngIf="drop && itemVariant == dropCardVariant"
      [content]="drop"
      (cardClick)="handleEventDropClick($event)"
    >
    </lib-event-drop-card>

    <!-- DROP MAIN -->
    <lib-drop-main-button
      *ngIf="drop && itemVariant == dropMainVariant"
      [drop]="drop"
    ></lib-drop-main-button>

    <!-- DROP SHARE -->
    <lib-event-drop-share-button
      *ngIf="drop && itemVariant == dropShareVariant"
      [content]="drop"
    >
    </lib-event-drop-share-button>

    <!-- PAGE -->
    <lib-landing-page-view
      *ngIf="page"
      [page]="page"
      [accountInfo]="slug?.accountInfo"
      (contentClick)="handleContentClick($event)"
    >
    </lib-landing-page-view>
  </div>
</div>

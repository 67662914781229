import { User } from '@angular/fire/auth';

export enum ApiSurfaces {
  API = 'api',
  AUTH = 'auth',
  BILLING = 'billing',
  END_USER = 'end-user',
  ANALYTICS = 'analytics-worker'
}

export interface IApiService {
  setCurrentUser(user: User);

  get currentUser();

  constructApiUrl(uri: string, service: ApiSurfaces);

  post<T>(
    service: ApiSurfaces,
    route: string,
    body?: any,
    additionalHeaders?: any
  );

  put<T>(service: ApiSurfaces, route: string, body?: any);

  patch<T>(service: ApiSurfaces, route: string, body?: any);

  delete<T>(service: ApiSurfaces, route: string, params?: any);

  get<T>(service: ApiSurfaces, route: string, params?: any);

  get$<T>(service: ApiSurfaces, route: string, params?: any);
}

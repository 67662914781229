import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

// App
import { EmbedModule } from './modules/embed/embed.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Libs
import {
  AnalyticsService,
  ApiService,
  ContentInteractionsService,
  DeviceService,
  getSharedProviders,
  LoginModule,
  RsvpConfirmationModule,
  ShareService
} from 'shared';
import {
  MessageModule,
  MockDeviceService,
  MockShareService,
  MockContentInteractionsService,
  MockApiService,
  MockAnalyticsService
} from 'uikit';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OverlayModule,
    EmbedModule,
    LoginModule,
    RsvpConfirmationModule,
    MessageModule.forRoot()
  ],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),

    // App
    ...getSharedProviders(environment),
    {
      provide: MockDeviceService,
      useExisting: DeviceService
    },
    {
      provide: MockShareService,
      useExisting: ShareService
    },
    {
      provide: MockContentInteractionsService,
      useExisting: ContentInteractionsService
    },
    {
      provide: MockApiService,
      useExisting: ApiService
    },
    {
      provide: MockAnalyticsService,
      useExisting: AnalyticsService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import {
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  ApplicationRef
} from '@angular/core';
import { DomPortalOutlet } from '@angular/cdk/portal';

export function createDomPortalHost(elRef: ElementRef, injector: Injector) {
  return new DomPortalOutlet(
    elRef.nativeElement,
    injector.get(ComponentFactoryResolver),
    injector.get(ApplicationRef),
    injector
  );
}

export function createDomPortalHostFromElement(
  element: Element,
  injector: Injector
) {
  return new DomPortalOutlet(
    element,
    injector.get(ComponentFactoryResolver),
    injector.get(ApplicationRef),
    injector
  );
}
